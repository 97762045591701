import React, { FunctionComponent } from 'react'

import SEO from '@sunburst/component-seo'
import logo from '@sunburst/shared-logo/assets/images/large/sunburst-logo-yellow-white.png'

import Page from '../components/Page'
import { Background, ErrorText, Logo } from '../styles/404.styles'

const NotFoundPage: FunctionComponent = () => (
  <Page>
    <Background>
      <SEO title="404 - Page Not Found" />
      <Logo alt="Sunburst Software Logo" src={logo} />
      <ErrorText>404 - Page Not Found</ErrorText>
    </Background>
  </Page>
)

export default NotFoundPage
