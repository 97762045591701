import styled from 'styled-components'

import { colors, fonts, layout } from '@sunburst/shared-styles'

export const Background = styled.div`
  align-items: center;
  background-color: ${colors.primary.medium};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`

export const Logo = styled.img`
  max-width: ${layout.sizing.xl1};
  width: 80%;
`

export const ErrorText = styled.h1`
  padding-top: ${layout.sizing.md3};
  display: block;
  font-size: ${fonts.sizes.xxl};
  font-weight: ${fonts.weights.light};
  color: white;

  ${layout.breakpoints.phoneDown} {
    font-size: ${fonts.sizes.xl};
  }
`
